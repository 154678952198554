var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pb-0",attrs:{"id":"contattiForm"}},[_c('jn-progress',{attrs:{"loading":_vm.loading}}),_c('v-row',{staticClass:"grey darken-3 pb-3"},[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"white--text text-center text-h5 pl-0 mt-10",attrs:{"cols":"11"}},[_vm._v("Non esitare a contattarci per darci suggerimenti, o per chiederci qualsiasi informazione."),_c('br'),_vm._v("Oppure, se preferisci, chiamaci al numero "),_c('b',{staticClass:"red--text-juranews"},[_vm._v("0835.16.53.922")]),_vm._v(".")]),_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"white--text mt-10",attrs:{"cols":"3"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12"}},[_vm._v("SERVIZIO CLIENTI")]),_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12"}},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"aria-hidden":"false","dark":""}},[_vm._v(" mdi-email ")]),_vm._v("servizioclienti@juranews.it ")],1)])],1),_c('v-row',{staticClass:"pt-0 mt-0"},[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12"}},[_vm._v("REDAZIONE")]),_c('v-col',{staticClass:"pt-0 mt-0",attrs:{"cols":"12"}},[_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"aria-hidden":"false","dark":""}},[_vm._v(" mdi-email ")]),_vm._v("redazione@juranews.it ")],1)])],1)],1),(_vm.messageSent)?_c('v-col',{staticClass:"white--text pt-16 mt-16",attrs:{"cols":"8"}},[_c('v-col',{staticClass:"red--text-juranews pb-0 mb-0 text-center",attrs:{"cols":"12"}},[_vm._v("Il messaggio è stato inviato!")]),_c('v-col',{staticClass:"red--text-juranews pb-0 mb-0 text-center",attrs:{"cols":"12"}},[_vm._v("Ti risponderemo il prima possibile.")])],1):_vm._e(),(!_vm.messageSent)?_c('v-col',{staticClass:"white--text",attrs:{"cols":"8"}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"id":"formContatti"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome","error-messages":errors,"dark":""},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"append-icon":'mdi-email',"label":"Email","type":"email","error-messages":errors,"dark":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Oggetto","error-messages":errors,"dark":""},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Messaggio","error-messages":errors,"dark":""},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"8"}},[_c('v-btn',{staticClass:"red text-h6 elevation-0 rounded-0 mt-2",attrs:{"disabled":invalid,"width":"200","dark":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" INVIA ")])],1),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"grey--text text-center",attrs:{"cols":"8"}},[_c('div',[_vm._v(" Compilando il presente form e cliccando sul tasto \"Invia\" o contattandoci via email o telefonicamente, invierai i tuoi dati personali a Juranet s.r.l, che li tratterà per rispondere alla tua richiesta e/o fornirti il servizio richiesto, come descritto nell'"),_c('router-link',{staticClass:"red--text-juranews",staticStyle:{"cursor":"pointer"},attrs:{"to":"/policies/privacy","tag":"span"}},[_vm._v("informativa sulla Privacy")]),_vm._v(" che ti invitiamo a leggere con cura. ")],1)]),_c('v-col',{attrs:{"cols":"3"}})],1)],1)]}}],null,false,1863411830)})],1):_vm._e()],1),_c('v-snackbar',{attrs:{"timeout":_vm.alertTimeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"red-juranews white--text",attrs:{"text":""},on:{"click":function($event){_vm.alert = false}}},'v-btn',attrs,false),[_vm._v(" Chiudi ")])]}}]),model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }